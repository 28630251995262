(function(battle, $, undefined)
{
    var battleData,
        battleQueue,
        battleSpeed,
        startFromStep,
        battleTempId,
        battleFunctionsDelay,
        battleStartDelay,
        battleBackground,
        battleDelayMod,
        battleLogActive,
        battleQueueSkip,
        battleReplaySteps,
        battleFinished,
        battleUpdatedHeader;

    var paused = false;

    // cache
    var $_modalBattle,
        $_modalBattleOverlay,
        $_healthSide,
        $_genjutsusSide,
	    $_effectsSide,
        $_lineupsSide,
        $_combatantsSide,
        $_dialogue;

    battle.init = function()
    {
        this.battleLogActive = false;
        this.battleFinished = false;
        this.battleReplaySteps = false;
        this.battleQueueSkip = false;
        this.battleUpdatedHeader = false;
        this.battleDelayMod = (typeof main.settingsConfig['FastForward'] !== 'undefined' && main.settingsConfig['FastForward'] === 1) ? main.settings['Speed'] : 1;
        this.cacheInitialized = [];
        this.battleStartDelay = 500;
        this.battleSpeed = this.battleDelayMod;
        this.battleTempId = 1;
        this.battleFunctionsDelay = {
            'printCost' : 1000,
            'printRewards' : 1000
        };

        battle.$_modalBattle = $('#modal-battle');
        battle.$_modalBattleOverlay = main.$_modalBattle.find('.c-overlay');

        battle.setBattleSettings();

        //this.uiEvents();
    };

    battle.setBattleSettings = function()
    {
        if ((typeof main.settingsConfig['Anim'] !== 'undefined' && main.settingsConfig['Anim'] === 0)) {
            battle.$_modalBattle.addClass('-c-animations-disabled');
        }
    };

    battle.uiEvents = function()
    {
        if ('events' in this.cacheInitialized)
            return true;

        this.cacheInitialized['events'] = true;

        this.$_modalBattle.on('click', '.battle-next', function()
        {
            battle.stopBattle();
            battle.exitBattle();
            battle.openSummary();
        });

        this.$_modalBattle.on('click', '#battle-log-button', clickBattleLogButton);
        this.$_modalBattle.on('click', '.js-battle-intro-begin', clickIntroPlayButton);
        this.$_modalBattle.on('click', '.js-battle-action-finish', clickFinishButton);
        this.$_modalBattle.on('click', '.js-battle-skip', clickSkipButton);
        this.$_modalBattle.on('click', '.js-play-toggle', clickMediaPlayToggleButton);
        this.$_modalBattle.on('click', '.js-battle-control-fastforward', clickMediaFastForwardToggleButton);
        this.$_modalBattle.on('click', '.js-battle-control-animations', clickMediaAnimationsToggleButton);
        this.$_modalBattle.on('click', '.js-battle-control-stop', clickMediaStopButton);
        this.$_modalBattle.on('click', '.js-battle-control-first', function() { clickMediaStepButton(0); });
        this.$_modalBattle.on('click', '.js-battle-control-last', function() { clickMediaStepButton('last'); });
        this.$_modalBattle.on('click', '.js-battle-rewatch', function() { battle.goToStep(0) });
	    this.$_modalBattle.on('click', '.js-battle-log-content .js-battle-log-step', clickBattleLogGotoStep);
        this.$_modalBattle.on('click', '.js-play-center', clickStepPlayButton);


        function clickBattleLogGotoStep()
        {
	        var $_this = $(this),
		        round = $_this.attr('data-round');

	        if (battle.$_modalBattle.find('.js-battle-presentation').hasClass('-c-active')) {
		        battle.battleQueue.dequeue();
	        }

	        battle.goToStep(round);

	        main.scrollToModal();
        }

        function clickBattleLogButton()
        {
            var $_this = $(this),
	            $_battleLog = $('#battle-log');

            if ($_this.hasClass('-c-sel'))
            {
	            $_this.removeClass('-c-sel');

	            $_battleLog.hide(500);

            	return false;
            }
            else
            {
	            $_this.addClass('-c-sel');

            	if ($_battleLog.find('.js-battle-log-content').hasClass('js-battle-log-loaded'))
	            {
		            $_battleLog.show(500);

		            return false;
	            }
            }

	        var sendData = {
	        	'ReplayID' : $_this.attr('data-replayid')
	        };

	        $.ajaxHandler({
		        url: '/ajax/loadBattleLog',
		        type: 'POST',
		        data: sendData,
		        cache: false
	        }, {
		        sourceElement: 'modal-content'
	        }).done(function(data)
	        {
		        $_battleLog.show(500).find('.js-battle-log-content').addClass('js-battle-log-loaded').html(data);
	        });
        }

        function clickStepPlayButton()
        {
            var $_this = $(this);

            $_this.removeClass('-c-paused');

	        battle.battleQueue.dequeue();
        }

        function clickMediaStopButton()
        {
            battle.showLoader();

            battle.waitForAnimations().done(function()
            {
                battle.stopBattle();
                battle.exitBattle();

                battle.$_modalBattle.find('.js-battle-finish').trigger('click');

                if (battle.battleData['HeaderLineupExp'] !== false) {
                    main.headerLineupExp(battle.battleData['HeaderLineupExp']);
                }

                battle.updateHeaderData();

                /*
                battle.closeBattleModal();

                setTimeout(function() {
                    battle.hideLoader();
                }, 500);
                */
            });
        }

        function clickMediaAnimationsToggleButton()
        {
            var $_this = $(this),
                enabled = ($_this.hasClass('-c-active')) ? 0 : 1;

            battle.$_modalBattle.toggleClass('-c-animations-disabled');

            $_this.toggleClass('-c-active');

            main.settingsConfig['Anim'] = enabled;

            main.setCookie('Config', main.settingsConfig);
        }

        function clickMediaFastForwardToggleButton()
        {
            var $_this = $(this),
                enabled = ($_this.hasClass('-c-active')) ? 0 : 1;

            $_this.toggleClass('-c-active');

            if (enabled)
            {
	            battle.battleDelayMod = main.settings['Speed'];

	            main.settingsConfig['FastForward'] = 1;
            }
            else
            {
	            battle.battleDelayMod = 1;

            	delete main.settingsConfig['FastForward'];
            }

            main.setCookie('Config', main.settingsConfig);
        }

        function clickMediaStepButton(step)
        {
            if (typeof step === 'string')
            {
                var stepPosition = (step == 'end') ? 0 : 1;

                try {
                    step = Object.keys(battle.battleData['Steps']).length - stepPosition;
                } catch(e) {
                    step = 0;
                }
            }

            battle.showLoader();

            battle.waitForAnimations().done(function()
            {
                battle.hideLoader();
                battle.goToStep(step);
            });
        }

        function clickMediaPlayToggleButton()
        {
            var $_this = $(this);

            if (battle.$_modalBattle.find('.js-play-center').hasClass('-c-paused')) {
	            return false;
            }

            $_this.addClass('-c-inuse');

            if ($_this.hasClass('-c-paused'))
            {
                battle.showLoader('-c-active -c-invisible');

                battle.waitForAnimations().done(function()
                {
                    $_this.removeClass('-c-paused -c-inuse');
                    battle.hideLoader('-c-active -c-invisible');

                    battle.battleQueue.start();
                });
            }
            else
            {
                battle.stopBattle();
                battle.showLoader('-c-active -c-invisible');

	            // set tooltips for genjutsus
	            battle.$_modalBattle.find('.js-battle-genjutsus .c-effect').addClass('js-item-tooltip').each(function()
	            {
		            var $_this = $(this),
			            tooltipPath = '/tooltip/genjutsu/' + $_this.attr('data-id');

		            $_this.attr('data-url', tooltipPath);
	            });

                battle.waitForAnimations().done(function()
                {
                    $_this.removeClass('-c-inuse').addClass('-c-paused');
                    battle.hideLoader('-c-active -c-invisible');
                });
            }
        }

        function clickSkipButton()
        {
            battle.stopBattle();
            battle.$_modalBattle.find('.js-battle-presentation').removeClass('-c-active');
            battle.showSummaryScreen();
            battle.showFinalHealth();
            battle.setLineupsExpGain();

            battle.battleQueue.running = false;
        }

        function clickIntroPlayButton()
        {
            battle.$_modalBattle.removeClass('-c-border-ontop').find('.js-battle-presentation').removeClass('-c-active');

            setTimeout(function() {
                battle.battleQueue.dequeue();
            }, 100);
        }

        function clickFinishButton()
        {
            var $_this = $(this);

            battle.$_modalBattle.removeClass('-c-border-ontop').find('.js-battle-summary').removeClass('-c-active');

            setTimeout(function()
            {
                battle.stopBattle();
                battle.exitBattle();
            }, 100);

            if ($_this.hasClass('js-close-battle'))
            {
                battle.closeBattleModal();

                setTimeout(function() {
                    battle.hideLoader();
                }, 500);

                if (!($_this.hasClass('js-skip-history')))
                    HistoryManager.goBack();
            }
        }
    };

    battle.uiScreen = function()
    {
        //if ('screen' in this.cacheInitialized)
        //    return true;

        this.cacheInitialized['screen'] = true;

        battle.$_dialogue = battle.$_modalBattle.find('.js-battle-dialogue');

        battle.$_combatantsSide = {
            '0' : battle.$_modalBattle.find('.js-battle-combatants .a-battle-side-0'),
            '1' : battle.$_modalBattle.find('.js-battle-combatants .a-battle-side-1')
        };

        battle.$_healthSide = {
            '1' : battle.$_modalBattle.find('.js-battle-health .a-battle-side-1')
        };

        battle.$_genjutsusSide = {
            '0' : battle.$_modalBattle.find('.js-battle-genjutsus .a-battle-side-0'),
            '1' : battle.$_modalBattle.find('.js-battle-genjutsus .a-battle-side-1')
        };

	    battle.$_effectsSide = {
		    '0' : battle.$_modalBattle.find('.js-battle-effects .a-battle-side-0'),
		    '1' : battle.$_modalBattle.find('.js-battle-effects .a-battle-side-1')
	    };

        battle.$_lineupsSide = {
            '0' : battle.$_modalBattle.find('.js-battle-lineups .a-battle-side-0'),
            '1' : battle.$_modalBattle.find('.js-battle-lineups .a-battle-side-1')
        };
    };

    battle.uiSummary = function()
    {

    };

    battle.initiateBattle = function(data)
    {
        battle.uiEvents();

        this.battleData = data;

        if (data['Details']['Background'])
            battle.$_modalBattle.find('.m-modal__bg img').attr('src', data['Details']['Background']);

        setTimeout(function()
        {
            battle.hideLoader();

            if (battle.battleData['SkipBattle'])
                battle.openSummary();
            else
            {
                battle.uiScreen();
                battle.openBattle();
            }

        }, battle.battleStartDelay);
    };

    battle.showAttackBox = function(params)
    {
        var data = battle.battleData['Steps'][params['Step']][params['Side']]['Attack'];

        var template = Handlebars.getTemplate('battle/attack-box'),
            $_attackBox = battle.$_combatantsSide[battle.oppositeSide(params['Side'])].find('.js-attack-box');

        var html = template(data);
        var $html = $($.parseHTML(html));

        $_attackBox.html($html.addClass('-c-animate'));

        setTimeout(function()
        {
            var delay = 0;

            $html.find('.pm-battle-attack__dmg > div').each(function(i)
            {
                var $_this = $(this);

                if (i == 0)
                {
                    $_this.addClass('-c-animate').delay(battle.battleDelayMod * 400).queue(function() {
                        $_this.addClass('-c-fade');
                    });
                }
                else
                {
                    $_this.delay(battle.battleDelayMod * (300 + i*200)).queue(function() {
                        $_this.addClass('-c-visible');
                    });
                }

                ++delay;
            });


            if (data['Health'])
            {
                data['Health']['Side'] = battle.oppositeSide(params['Side']);

                setTimeout(function() {
                    battle.editHealthBar(data['Health']);
                }, battle.battleDelayMod * (delay * 200));
            }

            setTimeout(function()
            {
                if (battle.battleQueue)
                    battle.battleQueue.dequeue();

            }, battle.battleDelayMod * (400 + (delay * 200)));
        }, battle.battleDelayMod * 200);
    };

    battle.attackAnimation = function(params)
    {
        var opponentSide = battle.battleData['Steps'][params['Step']][battle.oppositeSide(params['Side'])],
            attackAnim = (params['Attack']) ? params['Attack'] : battle.battleData['Teams'][params['Side']][params['TeamIndex']]['Lineup'][params['LineupIndex']]['Animation']['Attack'],
            defendAnim = (params['Defend']) ? params['Defend'] : battle.battleData['Teams'][battle.oppositeSide(params['Side'])][opponentSide['TeamIndex']]['Lineup'][opponentSide['LineupIndex']]['Animation']['Defend'];

        var $_activeninja = battle.$_combatantsSide[params['Side']].find('.js-active-ninja .c-card__pic');

	    $_activeninja.addClass('h-anim--' + attackAnim + '-' + params['Side']);

        setTimeout(function()
        {
	        battle.$_combatantsSide[battle.oppositeSide(params['Side'])].find('.js-active-ninja .c-card__pic').removeClass(function(i, v) {
		        return (v.match (/\bh-anim--\S+/g) || []).join(' ')
	        }).addClass('h-anim--shake');
        }, battle.battleDelayMod * 400);

        setTimeout(function() {
            battle.battleQueue.dequeue();
        }, battle.battleDelayMod * 300);
    };

    battle.showDialogue = function(params)
    {
        if (typeof battle.battleData['Dialogue'][params['Text']] == 'undefined')
            return;

	    var template = Handlebars.getTemplate('battle/dialogue-box');

	    var templateData = {
		    'Align' : params['Align'],
		    'Text' : battle.battleData['Dialogue'][params['Text']]
	    };

	    var html = template(templateData);
	    var $_html = $($.parseHTML(html));

	    battle.$_dialogue.append(
		    $_html.addClass('-c-visible')
	    );

        setTimeout(function() {
            battle.battleQueue.dequeue();
        }, 1);
    };

    battle.pauseStep = function()
    {
        battle.$_modalBattle.find('.js-play-center').addClass('-c-paused');
    };

    battle.createCardContainer = function(params)
    {
        var data = battle.battleData['Teams'][params['Side']][params['TeamIndex']]['Lineup'][params['LineupIndex']];

        var template = Handlebars.getTemplate('battle/active-ninja'),
            $_activeninja = battle.$_combatantsSide[params['Side']].find('.js-active-ninja');

        data['Picture'] = data['Picture'] || data['Identifier'];

        data['CardPath'] = jsData['Config']['Pack']['Url'] + 'img/cards/' + jsData['Config']['Pack']['Path'];

        if (jsData['Config']['DisableGif'] === true)
        	data['Card']['large'] = '.jpg';

        var html = template(data);
        var $html = $($.parseHTML(html));

        $_activeninja.html($html.addClass('-c-animate'));

	    // remove effects
	    battle.$_effectsSide[params['Side']].find('.js-effect-activations').html('');

        battle.$_lineupsSide[params['Side']].find('.js-battle-lineup').eq(params['TeamIndex']).find('.c-card[data-index="' + params['LineupIndex'] + '"]').removeClass('-c-hidden');

        setTimeout(function()
        {
            if (battle.battleQueue)
                battle.battleQueue.dequeue();
        }, battle.battleDelayMod * 200);
    };

    battle.activateBukijutsu = function(params)
    {
        var data = battle.battleData['Steps'][params['Step']][params['Side']]['Bukijutsu'];

        $.each(data, function(i, v)
        {
            $({}).delay(battle.battleDelayMod * (i*800)).queue(function(next)
            {
                battle.editBukijutsuBar(v['Side'], v);
                next();
            });
        });

        if (battle.battleQueue)
            battle.battleQueue.dequeue();
    };

	battle.changeElement = function(params)
	{
		var $_eleContainer = battle.$_combatantsSide[params['Side']].find('.c-card__ele:visible:first'),
			eleClass = 'c-card__ele  c-ele -ele-';

		if (typeof params['To'] !== 'undefined')
		{
			if (!$_eleContainer.length)
			{
				var eleHtml = '<div class="' + eleClass + '" style="display: none;"></div>';

				battle.$_combatantsSide[params['Side']].find('.c-card').append(eleHtml);

				$_eleContainer = battle.$_combatantsSide[params['Side']].find('.c-card__ele:first');
			}

			var $_eleClone = $_eleContainer.clone().attr('class', eleClass + params['To']).hide();

			$_eleContainer.fadeOut(500, function()
			{
				if (params['To']) {
					$(this).after($_eleClone.fadeIn(500));
				}

				$(this).remove();
			});
		}

		battle.battleQueue.dequeue();
	};

	battle.modifyGenjutsu = function(params)
	{
		battle.editGenjutsuBar(params['Side'], params);

		setTimeout(function()
		{
			if (battle.battleQueue)
				battle.battleQueue.dequeue();
		}, battle.battleDelayMod * (100));
	};

	battle.rechargeGenjutsu = function(params)
    {
	    battle.editGenjutsuBar(params['Side'], params);

	    setTimeout(function()
	    {
		    if (battle.battleQueue)
			    battle.battleQueue.dequeue();
	    }, battle.battleDelayMod * (800));
    };

	battle.modifyHealth = function(params)
	{
		battle.editHealthBar(params);

		setTimeout(function()
		{
			if (battle.battleQueue)
				battle.battleQueue.dequeue();
		}, battle.battleDelayMod * (400));
	};

	battle.activateGenjutsu = function(params)
    {
        var data = battle.battleData['Steps'][params['Step']][params['Side']]['Genjutsu'];

        var template = Handlebars.getTemplate('battle/genjutsu-trigger'),
            len = 0,
            sideLen = {
                0: 0,
                1: 0
            };

        $.each(data['Activated'][params['Stage']], function(i, v)
        {
            var templateData = {
            	'ID' : v['ID'],
                'Cost' : v['Cost'],
                'Text' : $.i18n._('genjutsu_activated', [v['Name']])
            };

            if (typeof v['Procs'] !== 'undefined')
            {
	            templateData['Procs'] = v['Procs'];
            	templateData['ProcsText'] = $.i18n._('genjutsu_procs', [v['Procs']]);

	            if (typeof v['ProcsTimes'] !== 'undefined') {
		            templateData['ProcsTimes'] = v['ProcsTimes'];
	            }
            }

            if (typeof v['Effects'] !== 'undefined') {
            	templateData['Multi'] = $.i18n._('genjutsu_multi');
            }

	        if (v['Absorbed']) {
		        templateData['Absorbed'] = $.i18n._('genjutsu_absorbed');
	        }

	        if (v['Blocked']) {
		        templateData['Absorbed'] = $.i18n._('genjutsu_blocked');
	        }

	        if (v['Learned']) {
		        templateData['Learned'] = $.i18n._('genjutsu_learned');
	        }

	        if (v['Receiver']) {
	        	templateData['Receiver'] = v['Receiver'].toLowerCase();
	        }

	        if (typeof v['PassAlong'] !== 'undefined') {
		        templateData['PassAlong'] = v['PassAlong'];
	        }

            var html = template(templateData);

            battle.$_genjutsusSide[v['Side']].find('.js-genjutsu-activations').append(
                $($.parseHTML(html)).delay(battle.battleDelayMod * (i*800)).queue(function(next)
                {
                	var $_this = $(this);

	                $_this.addClass('-c-animate');

	                if (v['Absorbed'] || v['Blocked'])
	                {
	                	setTimeout(function() {
			                $_this.addClass('-c-absorbed');
		                }, 300);
	                }

                    /*
                    setTimeout(function() {

                    }, battle.battleDelayMod * (i*1000));
                    */

                    battle.editGenjutsuBar(v['Side'], v);
                    next();
                })
            );

            ++sideLen[v['Side']];
            ++len;
        });

        setTimeout(function()
        {
            if (battle.battleQueue)
                battle.battleQueue.dequeue();
        }, battle.battleDelayMod * (len * 400));
    };

    battle.effectFocus = function(params)
    {
	    if (params['New'])
	    {
		    var template = Handlebars.getTemplate('battle/effect-focus');

		    var templateData = {
			    'PercentNew' : params['PercentNew']
		    };

		    var html = template(templateData);

		    battle.$_effectsSide[params['Side']].find('.js-effect-activations').append(
		    	$($.parseHTML(html)).addClass('-c-animate')
		    );
	    }
	    else
	    {
		    battle.$_effectsSide[params['Side']].find('.js-effect-activations .-type-focus').find('b').countTo({
			    from: params['PercentCur'],
			    to: params['PercentNew'],
			    speed: (battle.battleDelayMod * 1000),
			    refreshInterval: 100
		    });
	    }
    };

	battle.effectPoison = function(params)
	{
		var delay = (params['New']) ? 600 : 200;

		setTimeout(function()
		{
			if (params['New'])
			{
				var template = Handlebars.getTemplate('battle/effect-poison');

				var templateData = {
					'Turns' : params['Turns'],
					'TurnsMax' : params['TurnsMax']
				};

				var html = template(templateData);

				battle.$_effectsSide[params['Side']].find('.js-effect-activations').append(
					$($.parseHTML(html)).addClass('-c-animate')
				);
			}
			else
			{
				var $_effect = battle.$_effectsSide[params['Side']].find('.js-effect-activations').find('.-type-poison');

				if (params['Turns'])
				{
					$_effect.find('b').html(params['Turns']);
				}

				if (params['TurnsMax'])
				{
					$_effect.find('em').html(params['TurnsMax']);
				}

				if (params['Expire'])
				{
					$_effect.remove();
				}

				if (params['Update'])
				{
					$_effect.removeClass('-c-animate').delay(50).queue(function() {
						$(this).addClass('-c-animate').dequeue();
					});
				}
			}
		}, battle.battleDelayMod * delay);
	};

	battle.activateEffect = function(params)
	{
		var data = battle.battleData['Steps'][params['Step']][params['Side']]['Effect'];

		var len = 0,
			sideLen = {
				0: 0,
				1: 0
			};

		$.each(data['Activated'][params['Stage']], function(i, v)
		{
			var effectFunctionName = 'effect' + v['Effect'];

			window['battle'][effectFunctionName](v);

			++sideLen[v['Side']];
			++len;
		});

		setTimeout(function()
		{
			if (battle.battleQueue)
				battle.battleQueue.dequeue();
		}, battle.battleDelayMod * (len * 400));
	};

    battle.showLineup = function(params)
    {
        battle.$_lineupsSide[params['Side']].find('.c-card.js-position-card').removeClass('active -status-defeated -c-bl-gain -c-exp-gain -c-exp-gain-active').each(function(i)
        {
            var $_this = $(this);

            $_this.delay(battle.battleDelayMod * (i*100)).queue(function(next) {
                $_this.removeClass('-c-empty');
                next();
            });
        });
    };

    battle.editBukijutsuBar = function(side, data)
    {
        var $_bukiContainer = battle.$_combatantsSide[side].find('.c-card__buki');

	    if (typeof data['Width'] !== 'undefined') {
		    $_bukiContainer.find('.c-bar').css({width: data['Width'] + '%'});
	    }

	    if (typeof data['Bar'] !== 'undefined') {
		    $_bukiContainer.css({width: data['Bar'] + '%'});
	    }

        if (typeof data['Percent'] !== 'undefined') {
        	$_bukiContainer.find('.c-bar__fill').css({width: data['Percent'] + '%'});
        }

	    if (typeof data['New'] !== 'undefined')
	    {
		    setTimeout(function()
		    {
			    $_bukiContainer.find('.c-bar__text-cur').html(data['New']).addClass('-c-active').delay(500).queue(function() {
				    $(this).removeClass('-c-active').dequeue();
			    });
		    }, 250);
	    }
    };

    battle.editGenjutsuBar = function(side, data)
    {
	    if (typeof data['Width'] !== 'undefined') {
		    battle.$_genjutsusSide[side].find('.js-battle-genjutsu-bar').css({width: data['Width'] + '%'});
	    }

	    if (typeof data['Percent'] !== 'undefined') {
	    	battle.$_genjutsusSide[side].find('.c-bar__fill').css({width: data['Percent'] + '%'});
	    }

	    if (typeof data['Cur'] !== 'undefined' && typeof data['New'] !== 'undefined')
	    {
	    	battle.$_genjutsusSide[side].find('.c-bar__text-cur').addClass('-c-active').countTo({
			    from: data['Cur'],
			    to: data['New'],
			    speed: (battle.battleDelayMod * 400),
			    refreshInterval: 50,
			    onComplete: function()
			    {
				    var $_this = $(this);

				    setTimeout(function() {
					    $_this.removeClass('-c-active');
				    }, 200);
			    }
		    });
	    }

	    if (typeof data['Max'] !== 'undefined') {
		    battle.$_genjutsusSide[side].find('.c-bar__text-max').html('/' + data['Max']);
	    }

    };

    battle.editHealthBar = function(params)
    {
        var data = params;

	    if (typeof data['Bar'] !== 'undefined') {
		    battle.$_healthSide[params['Side']].find('.js-battle-health-bar').css({width: data['Bar'] + '%'});
	    }

	    if (typeof data['Percent'] !== 'undefined') {
	    	battle.$_healthSide[params['Side']].find('.c-bar__fill').css({width: data['Percent'] + '%'});
	    }

	    if (typeof data['Cur'] !== 'undefined' && typeof data['New'] !== 'undefined')
	    {
	    	battle.$_healthSide[params['Side']].find('.c-bar__text-cur').addClass('-c-active').countTo({
			    from: data['Cur'],
			    to: data['New'],
			    speed: (battle.battleDelayMod * 600),
			    refreshInterval: 50,
			    onComplete: function()
			    {
				    var $_this = $(this);

				    setTimeout(function() {
					    $_this.removeClass('-c-active');
				    }, 200);
			    }
		    });
	    }

	    if (typeof data['Max'] !== 'undefined') {
		    battle.$_healthSide[params['Side']].find('.c-bar__text-max').html('/' + data['Max']);
	    }
    };

    battle.createHealthBar = function(params)
    {
        var data = battle.battleData['Teams'][params['Side']][params['TeamIndex']]['Lineup'][params['LineupIndex']]['Health'];

        battle.$_healthSide[params['Side']].removeClass('-c-visible').addClass('-c-visible').delay(battle.battleDelayMod * 100).queue(function(next)
        {
            var $_this = $(this);

            $_this.find('.js-battle-health-bar').css({width: data['Width'] + '%'}).delay(battle.battleDelayMod * 300).queue(function(next)
            {
                $_this.find('.c-bar__fill').css({width: Math.round((data['Cur'] / data['Max']) * 100) + '%'});
                $_this.find('.c-bar__text-cur').html(data['Cur']);
                $_this.find('.c-bar__text-max').html('/' + data['Max']);

                setTimeout(function()
                {
                    if (battle.battleQueue)
                        battle.battleQueue.dequeue();

                    next();
                }, battle.battleDelayMod * 500);
            });

            next();
        });
    };

    battle.createGenjutsuBar = function(params)
    {
        var data = battle.battleData['Teams'][params['Side']][params['TeamIndex']]['Genjutsu'];

        battle.$_genjutsusSide[params['Side']].removeClass('-c-visible').addClass('-c-visible').delay(battle.battleDelayMod * 100).queue(function(next)
        {
            var $_this = $(this);

            $_this.find('.js-battle-genjutsu-bar').css({width: data['Width'] + '%'}).delay(battle.battleDelayMod * 200).queue(function(next)
            {
                $_this.find('.c-bar__fill').css({width: '100%'});
                $_this.find('.c-bar__text-cur').html(data['Cur']);
                $_this.find('.c-bar__text-max').html('/' + data['Max']);

                setTimeout(function()
                {
                    if (battle.battleQueue && (typeof params['SkipQueue'] === 'undefined' || params['SkipQueue'] === false))
                        battle.battleQueue.dequeue();

                    next();
                }, battle.battleDelayMod * 600);
            });

            next();
        });
    };

    battle.defeatAnimation = function(params)
    {
        battle.$_combatantsSide[battle.oppositeSide(params['Side'])].find('.js-attack-box').addClass('-c-fadeout');

        if (params['isDefeated'])
        {
            var sideData = battle.battleData['Steps'][params['Step']][params['Side']],
                defeatAnim = (params['Defeat']) ? params['Defeat'] : battle.battleData['Teams'][params['Side']][sideData['TeamIndex']]['Lineup'][sideData['LineupIndex']]['Animation']['Defeat'];

	        defeatAnim = 'h-anim--' + defeatAnim;

            var $_activeninja = battle.$_combatantsSide[params['Side']].find('.js-active-ninja');

            $_activeninja.addClass(defeatAnim);

            // remove effects
	        battle.$_effectsSide[params['Side']].find('.js-effect-activations').html('');
	        /*
	        battle.$_effectsSide[params['Side']].find('.activations .effect-trigger').removeClass('visible').delay(battle.battleDelayMod * 500).queue(function() {
	        	$(this).parent().html('');
	        });
	        */

            setTimeout(function()
            {
                battle.$_lineupsSide[params['Side']].find('.js-battle-lineup').eq(sideData['TeamIndex']).find('.c-card[data-index="' + sideData['LineupIndex'] + '"]').addClass('-status-defeated').removeClass('-c-exp-gain-active');

                $_activeninja.html('').removeClass(defeatAnim);

                if (battle.battleQueue && (typeof params['SkipQueue'] === 'undefined' || params['SkipQueue'] === false)) {
                    battle.battleQueue.dequeue();
                }
            }, battle.battleDelayMod * 1500);
        }
        else
        {
            setTimeout(function() {
                battle.battleQueue.dequeue();
            }, battle.battleDelayMod * 1500);
        }
    };

    battle.expGain = function(params)
    {
        var expGain = battle.battleData['Steps'][params['Step']][params['Side']]['ExpGain'];

        var $_lineupCard = battle.$_lineupsSide[params['Side']].find('.js-battle-lineup').eq(params['TeamIndex']).find('.c-card[data-index="' + params['LineupIndex'] + '"]'),
            $_expNumber = $_lineupCard.find('.c-card__exp-gain-nr b');

        $_lineupCard.removeClass('-c-exp-gain-active').addClass('-c-exp-gain');

        $_expNumber.countTo({
            from: parseInt($_expNumber.html()),
            to: expGain,
            speed: battle.battleDelayMod * 400,
            refreshInterval: 100,
            onComplete: function()
            {
                setTimeout(function()
                {
                    if (!$_lineupCard.hasClass('-status-defeated') && !battle.battleFinished) {
                        $_lineupCard.addClass('-c-exp-gain-active');
                    }
                }, battle.battleDelayMod * 800);
            }
        });
    };

    battle.endStep = function(params)
    {
        battle.$_genjutsusSide[0].find('.js-genjutsu-activations').html('');
        battle.$_genjutsusSide[1].find('.js-genjutsu-activations').html('');

        battle.$_combatantsSide[0].find('.js-attack-box').attr('class', 'pm-battle-attack  js-attack-box').html('');
        battle.$_combatantsSide[1].find('.js-attack-box').attr('class', 'pm-battle-attack  js-attack-box').html('');

        battle.$_dialogue.html('');

        if (battle.battleQueue && (typeof params['SkipQueue'] === 'undefined' || params['SkipQueue'] === false)) {
            battle.battleQueue.dequeue();
        }
    };

    battle.afterBattle = function(params)
    {
        //battle.$_lineupsSide[0].find('.expgained').removeClass('expgained');
        //battle.$_lineupsSide[1].find('.expgained').removeClass('expgained');

        battle.setLineupsExpGain();
    };

    battle.newRound = function(params)
    {
        battle.$_modalBattle.find('.pm-battle-round').addClass('-c-animate').find('b').html(params['Step'] + 1);

        setTimeout(function() {
            battle.$_modalBattle.find('.pm-battle-round').removeClass('-c-animate');
        }, battle.battleDelayMod * 1600);
    };

    battle.showIntroScreen = function()
    {
        if (battle.battleQueueSkip == true)
        {
            battle.battleQueue.dequeue();

            return false;
        }

        battle.$_modalBattle.addClass('-c-border-ontop').find('.js-battle-presentation').addClass('-c-active');
    };

	battle.showFinalHealth = function()
	{
		// only mission battles lost
		if (typeof battle.battleData['Details']['Mission'] === 'undefined' || !battle.battleData['Details']['Mission'] || battle.battleData['Details']['SideWon'] === 0)
			return false;

		var lastStep = (battle.battleData['Steps'].length - 1);

		try {

			var finalHealth = battle.battleData['Steps'][lastStep][0]['Attack']['Health'],
				finalOpponent = battle.battleData['Steps'][lastStep][1],
				finalOpponentHealthData = battle.battleData['Teams'][1][finalOpponent['TeamIndex']]['Lineup'][finalOpponent['LineupIndex']]['Health'];

			var $_battleHealth = $('.js-battle-health'),
				$_summaryButtons = $('.js-battle-summary .js-battle-buttons');

            if (!finalHealth) {
                return false;
            }

			$_summaryButtons.addClass('-c-faded');
			$_battleHealth.addClass('-c-display-above');

			finalHealth['Side'] = 1;
			finalHealth['Bar'] = finalOpponentHealthData['Width'];

			battle.editHealthBar(finalHealth);

			setTimeout(function()
			{
				$_battleHealth.animate({ opacity: 0.1 }, 500, function()
				{
					$_battleHealth.removeClass('-c-display-above').css({ opacity: 1 });
					$_summaryButtons.removeClass('-c-faded');
				});

			}, 800);

		} catch(e) {
			return false;
		}
	};

    battle.showSummaryScreen = function()
    {
        battle.battleFinished = true;

        battle.$_modalBattle.addClass('-c-border-ontop').find('.js-battle-summary').addClass('-c-active');

	    var treasureArray = {};

	    if (typeof battle.battleData['Treasures'] !== 'undefined') {
		    treasureArray['items'] = battle.battleData['Treasures'];
	    }

	    if (typeof battle.battleData['Material'] !== 'undefined' && typeof battle.battleData['Material']['Rolls'] !== 'undefined') {
		    treasureArray['material'] = battle.battleData['Material']['Rolls'];
	    }

        $.each(treasureArray, function(treasureCategory, treasures)
        {
	        var $_treasures = battle.$_modalBattle.find('.js-treasure-rolls-' + treasureCategory);

        	$.each(treasures, function(treasureIndex, treasureData)
	        {
	            var $_treasure = $_treasures.find('.js-treasure-roll').eq(treasureIndex),
	                attemptDelay = 0,
	                totalDuration = 0;

	            $.each(treasureData['Roll']['Attempts'], function(attemptIndex, attemptData)
	            {
	                setTimeout(function()
	                {
	                    $_treasure.find('.pm-battle-treasures__roll-nr').eq(attemptIndex).countTo({
	                        from: attemptData['Start'],
	                        to: attemptData['End'],
	                        speed: attemptData['Speed'],
	                        refreshInterval: attemptData['Interval'],
	                        onComplete: function()
	                        {
	                            if (attemptData['Success']) {
		                            $(this).addClass('-status-shake');
	                            } else {
	                                $(this).addClass('-status-failed');
	                            }

	                            if (attemptData['Duplicate']) {
	                                $(this).addClass('-status-duplicate');
	                            }
	                        }
	                    });
	                }, attemptDelay);

	                var totalDelay = attemptDelay + attemptData['Speed'];

	                attemptDelay = (attemptIndex + 1) * (attemptData['Speed'] * 0.75);

	                if (totalDelay > totalDuration)
	                    totalDuration = totalDelay;
	            });

	            setTimeout(function()
	            {
	                if (treasureData['Roll']['Success'])
	                {
	                    if (treasureData['Unknown'] === true)
	                        $_treasure.find('.c-item').addClass('js-item-tooltip').find('.c-item__name').html(treasureData['Name']);

	                    setTimeout(function()
	                    {
		                    var successClass = '-status-success';

		                    if (treasureData['Roll']['Class'])
			                    successClass += ' ' + treasureData['Roll']['Class'];

		                    if (treasureCategory !== 'material') {
		                    	$_treasure.addClass(successClass);
		                    }

		                    if (treasureCategory === 'material') {
			                    $_treasure.addClass('-status-done');
		                    }
	                    }, 500);
	                }
	                else
	                {
	                    $_treasure.addClass('-status-failed');
	                }

	            }, totalDuration + 250);
	        });
        });

        if (battle.battleData['HeaderLineupExp'] !== false) {
            main.headerLineupExp(battle.battleData['HeaderLineupExp']);
        }

	    if (battle.battleData['HeaderLineupBloodlineLevels'] !== false)
	    {
		    $.each(battle.battleData['HeaderLineupBloodlineLevels'], function(i, bloodlineLevelUp)
		    {
			    main.showBloodlineLevelUp(bloodlineLevelUp);
		    });
	    }

        battle.updateHeaderData();
    };

    battle.setLineupsExpGain = function()
    {
        $.each(battle.battleData['Lineups'], function(side, sideData)
        {
            $.each(sideData, function(teamIndex, teamData)
            {
                // summon exp
                battle.$_lineupsSide[side].find('.js-lineup-summon .c-card__exp-gain-nr').closest('.c-card').addClass('-c-exp-gain');

                // lineup extra exp
	            battle.$_lineupsSide[side].find('.js-lineup-extra .c-card__exp-gain-nr').closest('.c-card').addClass('-c-exp-gain');

                // ninja exp
                $.each(teamData, function(position, lineupData)
                {
	                if (!lineupData['ExpGain'] && !lineupData['BloodlineExpGain'])
	                	return true;

	                var $_lineupCard = battle.$_lineupsSide[side].find('.js-battle-lineup').eq(teamIndex).find('.c-card[data-index="' + lineupData['LineupIndex'] + '"]');

                    if (lineupData['ExpGain'])
                    {
	                    var $_expNumber = $_lineupCard.find('.c-card__exp-gain-nr b');

                        $_lineupCard.removeClass('-c-exp-gain-active').addClass('-c-exp-gain');

                        $_expNumber.html(lineupData['ExpGain']);

                        setTimeout(function()
                        {
                            if (lineupData['ExpGainBonus'])
                            {
                                $_expNumber.countTo({
                                    from: lineupData['ExpGain'],
                                    to: parseInt(lineupData['ExpGain'] + lineupData['ExpGainBonus']),
                                    speed: (battle.battleDelayMod * 800),
                                    refreshInterval: 100
                                });
                            }
                        }, 800);
                    }

	                if (lineupData['BloodlineExpGain'])
	                {
		                var $_blExpNumber = $_lineupCard.find('.c-card__bl-gain-nr b');

		                $_lineupCard.addClass('-c-bl-gain');

		                $_blExpNumber.html(lineupData['BloodlineExpGain']);
	                }
                });
            });
        });
    };

    battle.setHeaderLineupExpGain = function()
    {

    };

    battle.updateHeaderData = function()
    {
        if (battle.battleUpdatedHeader)
            return false;

        battle.battleUpdatedHeader = true;

        if (typeof battle.battleData['HeaderRewards'] != 'undefined' && battle.battleData['HeaderRewards'] !== null) {
            main.updateHeaderTeamInfo(battle.battleData['HeaderRewards']);
        }
    };

    battle.clickPlayButton = function()
    {

    };

    battle.oppositeSide = function(side)
    {
        return (side == 0) ? 1 : 0;
    };

    battle.showLoader = function(type)
    {
        type = type || '-c-active';

        this.$_modalBattleOverlay.addClass(type);
    };

    battle.hideLoader = function(type)
    {
        type = type || '-c-active';

        this.$_modalBattleOverlay.removeClass(type);
    };

    // TODO: remove, replaced by below
    battle.closeBattleModal2 = function()
    {
        battle.$_modalBattle.removeClass('-c-slide-down  js-modal-visible');

        battle.$_modalBattle.css({
            'top' : -1 * (battle.$_modalBattle.height() + 65)
        }).removeClass('-c-slide-down  js-modal-visible').delay(1000).hide(1);

        /*
        setTimeout(function() {
            battle.$_modalBattle.hide(1);
        }, 1000);
        */

	    main.$_centercolOverlay.hide();
        //$('.centercol-overlay').hide();
    };

	battle.closeBattleModal = function(speed)
	{
		speed = (typeof speed != 'undefined') ? speed : 600;

		main.$_modalBattle.find('.js-battle-inner').html('');

		main.$_modalBattle.removeClass('-c-slide-down  js-modal-visible');

		setTimeout(function()
		{
			main.$_modalBattle.hide(1);
		}, speed);

		if (!main.$_modalSidebar.is(':visible'))
			main.$_centercolOverlay.hide();
	};

    battle.openSummary = function()
    {
        battle.uiSummary();

        this.$_modalBattle.find('.js-battle-screen').hide();
        this.$_modalBattle.find('.js-battle-summary').addClass('-c-active');

        battle.showSummaryScreen();
    };

    battle.openBattle = function()
    {
        battle.setStartStep(0);

        this.$_modalBattle.find('.js-battle-screen').show();
        this.$_modalBattle.find('.js-battle-summary').removeClass('-c-active');

        battle.startBattle();
    };

    battle.stopBattle = function()
    {
        if (this.battleQueue)
        {
            this.battleQueue.pause();
        }
    };

    battle.exitBattle = function()
    {
        battle.updateHeaderData();

        if (main.afterBattleQueue && $.isFunction(main.afterBattleQueue.promise)) {
            main.afterBattleQueue.resolve();
        }

        this.battleQueue = null;
        battle.battleUpdatedHeader = false;

        //$('.battle-summary').hide();
    };

    battle.resetBattle = function()
    {
        battle.endStep({'SkipQueue' : true});

        battle.$_modalBattle.removeClass('-c-border-ontop').find('.js-battle-presentation, .js-battle-summary').removeClass('-c-active');
        battle.$_modalBattle.find('.js-battle-screen').addClass('-c-active');

        battle.$_modalBattle.find('.js-battle-genjutsu-bar, .js-battle-health-bar').css({'width' : '0'}).find('.c-bar__fill').css({'width' : '0'});
    };

    battle.waitForAnimations = function()
    {
        var defer = $.Deferred(),
            counter = 0;

        (function checkWaiting()
        {
            if (battle.battleQueue.paused === true || battle.battleQueue.waitingForPause === false || counter > 3 || !battle.battleQueue.queue.length || battle.battleFinished === true)
            {
                defer.resolve();
            }
            else
            {
                ++counter;
                setTimeout(checkWaiting, 1000);
            }
        })();

        return defer.promise();
    };

    battle.goToStep = function(step)
    {
    	if (this.$_modalBattle.find('.js-play-toggle').hasClass('-c-paused'))
	    {
		    battle.waitForAnimations().done(function()
		    {
			    battle.battleQueue.start();
		    });
	    }

        battle.stopBattle();

        battle.showLoader();

        if (step > 0)
        {
            battle.$_modalBattle.removeClass('notransition');

            battle.battleReplaySteps = true;
            battle.battleQueueSkip = true;
            battle.battleDelayMod = 0;
        }

        battle.waitForAnimations().done(function()
        {
            battle.resetBattle();
            battle.setStartStep(step);
            battle.hideLoader();
            battle.startBattle();
        });
    };

    battle.resetPlayToggleButton = function()
    {
        this.$_modalBattle.find('.js-play-toggle').removeClass('-c-paused -c-inuse');
    };

    battle.setStartStep = function(step)
    {
        this.startFromStep = step;
    };

    battle.startBattle = function()
    {
        ++this.battleTempId;

        battle.battleFinished = false;
        this.battleQueue = new Queue();

        battle.resetPlayToggleButton();

        /*
        if (battle.startFromStep > 0)
        {
            battle.battleQueueSkip = true;
            battle.battleDelayMod = 0.5;


        }
        */

        //main.showLevelUp(this.battleData['LevelUp']);


        if (typeof this.battleData['Steps'] == 'undefined')
            return false;

        $.each(this.battleData['Steps'], function(step, stepData)
        {
            if (battle.battleReplaySteps == true && battle.startFromStep == step)
            {
                battle.battleQueue.add(function()
                {
                    battle.battleReplaySteps = false;
                    battle.battleQueueSkip = false;
                    battle.battleDelayMod = battle.battleSpeed;

                    battle.$_modalBattle.removeClass('notransition');
                    battle.hideLoader();

                    setTimeout(function()
                    {
                        battle.$_modalBattle.find('.js-play-toggle').addClass('-c-paused');
                    }, 10);

                    return false;
                });
            }

            $.each(stepData, function(side, sideData)
            {
                if (sideData['Functions'])
                {
                    $.each(sideData['Functions'], function(k, functionDetails)
                    {
                        battle.battleQueue.add(function()
                        {
                            if (typeof functionDetails['Params']['Step'] === 'undefined') {
                                functionDetails['Params']['Step'] = step;
                            }

                            if (typeof functionDetails['Params']['Side'] === 'undefined') {
                                functionDetails['Params']['Side'] = side;
                            }

                            if (typeof functionDetails['Params']['LineupIndex'] === 'undefined') {
                                functionDetails['Params']['LineupIndex'] = sideData['LineupIndex'];
                            }

                            if (typeof functionDetails['Params']['TeamIndex'] === 'undefined') {
                                functionDetails['Params']['TeamIndex'] = sideData['TeamIndex'];
                            }

                            window['battle'][functionDetails['Name']].call(null, functionDetails['Params']);

                            if (typeof functionDetails['Params']['SkipQueue'] === 'undefined' || functionDetails['Params']['SkipQueue'] === false) {
                                return false;
                            }
                        });
                    });
                }
            });
        });

        setTimeout(function() {
            if (battle.battleQueue) {
                battle.battleQueue.start();
            }
        }, 10);
    };

    battle.getPreviousStepsData = function(step)
    {
        $.each(this.battleData['Steps'][step], function(side, sideData)
        {
            if (sideData['Functions'])
            {
                $.each(sideData['Functions'], function(k, functionDetails)
                {
                    if (functionDetails['Name'] == 'defeatAnimation' && functionDetails['Params']['isDefeated'] === true)
                    {
                        var lineupIndex = battle.battleData['Steps'][step][functionDetails['Params']['Side']]['LineupIndex'];

                        battle.$_lineupsSide[functionDetails['Params']['Side']].find('.lineup-box .card').eq(lineupIndex).addClass('-status-defeated');
                    }

                    // get last card

                    // get hp of last card

                    // get genjutsus of last card
                });
            }
        });
    };

}(window.battle = window.battle || {}, jQuery));

$(function()
{
    battle.init();
});
